import deApp from "@/app/_messages/de.json"
import type { Nullable, SchemaPick } from "@/global"
import { getTranslation } from "@/i18n"
import dayjs from "dayjs"
import type {
  addresses,
  bank_accounts,
  countries,
  organizations,
  participation_type,
  persons,
} from "dcp-types"
import type { Selectable } from "kysely"
import type { DateTimeFormatOptions } from "next-intl"
import { NumericFormat, type NumericFormatProps } from "react-number-format"
import { arrayOfAll } from "../types/arrayOfAll"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

export function getDateFormat(locale: string): DateTimeFormatOptions {
  switch (locale) {
    default:
      return {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
  }
}

export function getDateTimeFormat(locale: string): DateTimeFormatOptions {
  switch (locale) {
    default:
      return {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }
  }
}

export function getDayjsTimeFormat() {
  return "HH:mm"
}

export function getDayjsDateFormat() {
  return "DD.MM.YYYY"
}

export function getDayjsDateTimeFormat() {
  return "DD.MM.YYYY HH:mm"
}

/**
 * This function converts a dayjs object to a custom ISO string.
 * It can be used to convert the date to a timezone independent format.
 */
export function toCustomISOString(
  date: dayjs.Dayjs | null | undefined,
  {
    convertTimezone = true,
    includeTime = true,
  }: {
    convertTimezone?: boolean
    includeTime?: boolean
  } = {
    convertTimezone: true,
    includeTime: true,
  },
) {
  if (!date || !date?.isValid() || Number.isNaN(date)) {
    return null
  }

  function pad(num: number) {
    return String(num).padStart(2, "0")
  }

  const d = convertTimezone ? date.clone().utc() : date
  const time = includeTime
    ? `${pad(d.hour())}:${pad(d.minute())}:${pad(d.second())}.000`
    : "00:00:00.000"

  return `${d.year()}-${pad(d.month() + 1)}-${pad(d.date())}T${time}Z`
}

export function displayPrettyPhoneNumber(ugly: string): string {
  // Remove any non-numeric characters from the input phone number
  const cleanedPhoneNumber = ugly.replace(/[^0-9]/g, "")

  if (cleanedPhoneNumber.length >= 10) {
    // Format the cleaned phone number with parentheses and dashes
    const formattedPhoneNumber = cleanedPhoneNumber.replace(
      /(\d{2})(\d{3})(\d{5})/,
      "+$1 $2 $3",
    )
    return formattedPhoneNumber
  }
  const formattedPhoneNumber = cleanedPhoneNumber.replace(
    /(\d{4})(\d{1})/,
    "$1 $2",
  )
  return formattedPhoneNumber
}

export function toMailbox(input: { email: string; name: string }) {
  return `"${input.name}" <${input.email}>`
}

export function parseMailbox(mailbox?: string | null) {
  // ensure to accept both "Name <email>" and "email" formats
  // "Name <email>" -> { name: string, email: string }
  // "\"Name\" <email>" -> { name: string, email: string }
  // "email" -> { email: string }

  const matches = mailbox?.match(/"?(.*?)"?\s*<(.*)>/)
  if (matches && matches.length >= 3) {
    return {
      name: matches[1],
      email: matches[2],
    }
  }

  const emailOnly = mailbox?.match(/<?(.*)>?/)
  return emailOnly
    ? {
        email: emailOnly[1],
      }
    : {
        email: "",
      }
}

export function formatCurrency(number: number | undefined | null = 0) {
  return (Number.isNaN(number ?? 0) ? 0 : (number ?? 0)).toLocaleString(
    "de-DE",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  )
}

export function parseFormattedCurrency(
  value: null | undefined | string = "0",
  options?: {
    thousandSeparator?: string | false // default is "."
    decimalSeparator?: string | false // default is ","
  },
): number {
  const { thousandSeparator = ".", decimalSeparator = "," } = options ?? {}

  if (value === null || value === undefined) {
    return 0
  }

  // ensure the german notation "1.000,55" is parsed correctly
  const parsedValue = Number.parseFloat(
    value
      .replaceAll(thousandSeparator ? thousandSeparator : "", "")
      .replaceAll(decimalSeparator ? decimalSeparator : "", "."),
  )

  return Number.isNaN(parsedValue) ? 0 : parsedValue
}

/**
 * Format bytes as human-readable text.
 *
 * @param numer_of_bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(numer_of_bytes: number, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024
  let bytes = numer_of_bytes

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${bytes.toFixed(dp)} ${units[u]}`
}

export function formatPersonInitials(
  person?: SchemaPick<persons, "first_name" | "last_name">,
) {
  return (
    person?.first_name?.at(0)?.toUpperCase() ??
    person?.last_name?.at(0)?.toUpperCase() ??
    "" ??
    ""
  )
}

export type person_title = "dr" | "prof" | "prof-dr"

export const personTitles = arrayOfAll<person_title>()([
  "dr",
  "prof",
  "prof-dr",
])

export function isPersonTitle(value: unknown): value is person_title {
  return personTitles.includes(value as person_title)
}

interface FormatPersonNameOptions {
  withTitle?: boolean
  withSalutation?: boolean
}

export function formatPersonName(
  person?: Nullable<
    SchemaPick<persons, "first_name" | "last_name" | "title" | "salutation">
  >,
  options?: FormatPersonNameOptions,
) {
  const { t } = getTranslation(deApp)
  const { withTitle = true, withSalutation = true } = options ?? {}

  const { first_name, last_name, title, salutation } = person || {}

  const nameParts: (string | null | undefined)[] = [first_name, last_name]

  if (withTitle && title) {
    nameParts.unshift(isPersonTitle(title) ? t(`title.${title}.label`) : title)
  }

  if (withSalutation && (salutation === "female" || salutation === "male")) {
    nameParts.unshift(t(`persons.salutation.${salutation}`))
  }

  return nameParts.filter(Boolean).join(" ")
}

export function formatParticipantName(
  participant?: {
    participation_type?: participation_type
    person?: SchemaPick<
      persons,
      "first_name" | "last_name" | "title" | "salutation"
    >
    organization?: SchemaPick<organizations, "name">
  } | null,
  options?: { person: FormatPersonNameOptions },
): string {
  const { t } = getTranslation(deApp)

  if (participant?.person) {
    return formatPersonName(participant?.person, options?.person)
  }
  if (participant?.organization?.name) {
    return participant?.organization.name
  }
  return t(`participants.participation_type.${participant?.participation_type}`)
}

type SalutationEntity =
  | SchemaPick<persons, "first_name" | "last_name" | "salutation" | "title">
  | SchemaPick<organizations, "name">

export function formatSalutation(
  entity?: SalutationEntity | null,
  format: "formal" | "informal" = "formal",
) {
  const { t } = getTranslation(deApp)

  const isPerson = (
    entity?: SalutationEntity | null,
  ): entity is Selectable<persons> =>
    !!(entity as Selectable<persons>)?.first_name ||
    !!(entity as Selectable<persons>)?.last_name
  const isOrganization = (
    entity?: SalutationEntity | null,
  ): entity is Selectable<organizations> =>
    !!(entity as Selectable<organizations>)?.name

  if (isPerson(entity)) {
    const name = (() => {
      const { t: tApp } = getTranslation(deApp)

      const formattedTitle = isPersonTitle(entity.title)
        ? tApp(`title.${entity.title}.label`)
        : entity.title

      if (format === "informal") {
        return formattedTitle
          ? `${formattedTitle} ${formatPersonName(entity, {
              withTitle: false,
              withSalutation: false,
            })}`
          : entity.first_name
      }
      return formattedTitle
        ? `${formattedTitle} ${entity.last_name}`
        : entity.last_name
    })()

    if (!name) {
      return t("salutation.fallback")
    }
    if (entity.salutation === "male") {
      return t(`salutation.male.${format}`, {
        name,
      })
    }
    if (entity.salutation === "female") {
      return t(`salutation.female.${format}`, {
        name,
      })
    }
    return t(`salutation.diverse.${format}`, {
      name: formatPersonName(entity, {
        withTitle: format === "formal",
        withSalutation: false,
      }),
    })
  }

  if (isOrganization(entity)) {
    return t(`salutation.organization.${format}`)
  }

  return t("salutation.fallback")
}

export function formatIBAN(iban: string | null | undefined = "") {
  return iban?.replace(/(.{4})/g, "$1 ").trim() ?? ""
}

export function formatBankAccount(
  account:
    | Selectable<Omit<bank_accounts, "entity_id" | "type">>
    | null
    | undefined,
) {
  if (!account?.iban) {
    return ""
  }

  return `IBAN: ${formatIBAN(account?.iban)}${account?.bic ? `, BIC: ${account?.bic}` : ""}`
}

export function formatNumber(
  number: string | number | undefined | null = 0,
  options?: Intl.NumberFormatOptions,
) {
  const parsedNumber =
    typeof number === "string" ? Number.parseFloat(number) : number
  return (
    Number.isNaN(parsedNumber ?? 0) ? 0 : (parsedNumber ?? 0)
  ).toLocaleString("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...(options ?? {}),
  })
}

interface FormatAddressStartlineOptions {
  format?: "short" | "long"
}
export function formatAddressStartline(
  lines?: Array<string | null> | string | null,
  options: FormatAddressStartlineOptions = {},
) {
  const { format } = options
  const [line1, line2] = Array.isArray(lines) ? lines : [lines]

  if (line1 && line2) {
    if (format === "short") {
      return `${(line1 ?? "").trim()} ${(line2 ?? "").trim()}`.trim()
    }

    return `${(line1 ?? "").trim()}\n${(line2 ?? "").trim()}`
  }

  if (line1 && !line2) {
    return line1.trim()
  }

  if (line2 && !line1) {
    return line2.trim()
  }

  return ""
}

export interface FormatAddressOptions {
  format?: "short" | "long"
  shortFormatDelimiter?: string
  withCountry?: boolean
}

type FormatAddressData = SchemaPick<
  addresses,
  "street" | "city" | "zip",
  false
> & {
  country: SchemaPick<countries, "name_de">
}

export function formatAddress(
  address?: FormatAddressData | null,
  options: FormatAddressOptions = {},
) {
  const { format, shortFormatDelimiter = " | ", withCountry } = options

  if (format === "short") {
    return [
      address?.street,
      [address?.zip, address?.city].filter(Boolean).join(" "),
    ]
      .filter(Boolean)
      .join(shortFormatDelimiter)
      .trim()
  }

  return `${address?.street ?? ""}
${address?.zip ?? ""} ${address?.city ?? ""}
${withCountry ? (address?.country?.name_de ?? "") : ""}`.trim()
}

export function formatAddressWithStartline(
  lines?: Array<string | null> | string | null,
  address?: FormatAddressData | null,
  options: FormatAddressStartlineOptions &
    FormatAddressOptions & {
      lineBreakAfterName?: boolean
    } = {},
) {
  const { format, withCountry, lineBreakAfterName } = options

  return `${formatAddressStartline(lines, {
    format,
  }).trim()}${lineBreakAfterName ? "\n" : ""}
${formatAddress(address, { format, withCountry })}`.trim()
}

export function formatRecipientAddress(
  recipient: {
    person?: FormatPersonAddressInput | null
    contactPerson?: FormatPersonAddressInput | null
    organization?: FormatOrganizationAddressInput | null
  } | null,
  options?: {
    address?: FormatAddressOptions
    person?: FormatPersonNameOptions
  },
) {
  if (recipient?.organization) {
    return formatOrganizationAddress(recipient?.organization, options)
  }
  if (recipient?.person) {
    return formatPersonAddress(recipient?.person, options)
  }
  if (recipient?.contactPerson) {
    return formatPersonAddress(recipient?.contactPerson, options)
  }
  return ""
}

export type FormatPersonAddressInput = SchemaPick<
  persons,
  "first_name" | "last_name" | "title" | "salutation"
> & {
  address?: FormatAddressData | null
}
export const formatPersonAddress = (
  person?: FormatPersonAddressInput | null,
  options?: {
    address?: FormatAddressOptions
    person?: FormatPersonNameOptions
  },
) => {
  const { address: addressOptions = {}, person: personOptions = {} } =
    options ?? {}

  return formatAddressWithStartline(
    formatPersonName(person, personOptions),
    person?.address,
    {
      withCountry: true,
      ...addressOptions,
    },
  ).trim()
}

export type FormatOrganizationAddressInput = SchemaPick<
  organizations,
  "name"
> & {
  address?: FormatAddressData | null
}

export const formatOrganizationAddress = (
  organization?: FormatOrganizationAddressInput | null,
  options?: {
    address?: FormatAddressOptions
  },
) => {
  const { address: addressOptions = {} } = options ?? {}

  return formatAddressWithStartline(organization?.name, organization?.address, {
    withCountry: true,
    ...addressOptions,
  }).trim()
}

export const CurrencyFormatMask = function NumericFormatCustom({
  ref,
  ...props
}: {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
} & {
  ref: React.RefObject<NumericFormatProps>
}) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
      fixedDecimalScale
    />
  )
}

export const RvgFactorFormatMask = function NumericFormatCustom({
  ref,
  ...props
}: {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
} & {
  ref: React.RefObject<NumericFormatProps>
}) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      valueIsNumericString
      decimalScale={2}
      decimalSeparator=","
    />
  )
}
