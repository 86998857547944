"use client"

import { useTranslation } from "@/i18n"
import { useEffect, useMemo, useState } from "react"
import {
  browserName,
  browserVersion,
  deviceType,
  osName,
  osVersion,
} from "react-device-detect"
import appDe from "@/app/_messages/de.json"
import { trpc } from "@/app/_trpc/client"
import { getDayjsDateTimeFormat } from "@/utils/format"
import dayjs from "dayjs"

/**
 * TODO: add texts to i18n
 */
const getMailtoSupportHref = (
  reporter: string,
  formattedSystemInformation: string,
): string =>
  `mailto:support@rightflow.de?subject=${encodeURIComponent(
    `Problembericht von ${reporter}`,
  )}&body=${encodeURIComponent(
    `Bitte beschreibe dein Anliegen hier:\n\n${
      formattedSystemInformation ?? "unbekannt"
    }`,
  )}`

export default function useGetMailToSupport(reporterName: string) {
  const { t: tApp } = useTranslation(appDe)
  const { data: systemInfo, isFetching } = trpc.system.version.useQuery(
    undefined,
    {
      retry: false,
    },
  )
  const [mounted, setMounted] = useState<boolean>()

  const isClient = typeof window !== "undefined"

  const mailTo = useMemo(() => {
    const clientTime = new Date().toISOString()
    const userAgent = isClient ? window.navigator.userAgent : ""
    const systemVersion = systemInfo?.version ?? tApp("systemVersion.unknown")
    const releaseDate = tApp("systemVersion.releaseDate", {
      date: systemInfo?.release_date
        ? dayjs(systemInfo?.release_date).format(getDayjsDateTimeFormat())
        : tApp("systemVersion.unknown"),
    })

    return getMailtoSupportHref(
      reporterName,
      `
• Client time: ${clientTime}      
• User Agent: ${userAgent}
• Browser: ${browserName} (${browserVersion})
• OS: ${osName} (${osVersion})
• Device: ${deviceType}
• System version: ${systemVersion}
• Release date: ${releaseDate}
• URL: ${isClient ? window.location.href : "unknown"}
`,
    )
  }, [
    isClient,
    reporterName,
    systemInfo?.release_date,
    systemInfo?.version,
    tApp,
  ])

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return {
      mailTo: "",
      isFetching: true,
    }
  }

  return { mailTo, isFetching }
}
