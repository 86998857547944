"use client"

import UnexpectedError from "@/app/_components/UnexpectedError"

interface ErrorProps {
  error?: Error
  reset?: () => void
}

export default function PageError({ error, reset }: ErrorProps) {
  return <UnexpectedError error={error} reset={reset} />
}
