export type PruneIdReturn<T extends { id: string | null | undefined }> =
  Exclude<keyof T, "id">[]
export const pruneId = <T extends { id: string | null | undefined }>(
  value: (keyof T)[],
): PruneIdReturn<T> =>
  value.filter((v): v is Exclude<keyof T, "id"> => v !== "id")
export const arrayOfAll =
  <T>() =>
  <U extends T[]>(
    array: U & ([T] extends [U[number]] ? unknown : "Invalid") & { 0: T },
  ) =>
    array
